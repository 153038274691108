<script setup lang="ts">
import type { Game, Error } from "@/types";

const props = defineProps<{
	data?: Game;
	locationTid?: string;
}>();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { t } = useT();
const route = useRoute();
const error: Ref<Error> = useError();

const { open, close } = useNlcModals();
const { handleOpenGame } = useOpenGame(open);
const isGuest = useIsGuest();

const handleGameOfWeekClick = async () => {
	dispatchGAEvent({
		event: "click_button",
		location: "reward_cabinet",
		button_name: "play"
	});
	if (route.query?.game) {
		navigateTo(route.path, { replace: true });
		close("LazyOModalGame");
	}

	if (error.value && error.value?.statusCode === 404) {
		clearError({ redirect: "/issues/all-games/" });
		await navigateTo({
			path: "/issues/all-games/",
			query: { game: props.data?.slug, isMoney: !isGuest.value ? "true" : undefined }
		});
	}
	handleOpenGame(props.data?.slug);
};
</script>

<template>
	<div
		:location-tid="locationTid"
		:data-tid="locationTid ? `game-of-week-${locationTid}` : null"
		@click="handleGameOfWeekClick"
	>
		<NuxtImg
			v-if="data?.img"
			class="icon"
			:src="`${baseImageUrl}${data?.img}`"
			width="120"
			height="120"
			loading="lazy"
			format="webp"
			alt="game of week"
		/>

		<div class="content">
			<ABadge class="badge" background="var(--additional-a-1)" variant="info" autosize>
				<AText class="color-neutral-100" variant="ternopil" :modifiers="['bold', 'uppercase']">{{
					t("Game of the week")
				}}</AText>
			</ABadge>
			<AText class="color-neutral-100" variant="toledo" :modifiers="['left', 'bold']" as="div">
				{{ data?.title }}
			</AText>
		</div>
		<AButton variant="primary" size="s" :data-tid="locationTid ? `reward-btn-${locationTid}` : null">
			<AText variant="topeka" :modifiers="['uppercase']">{{ t("Play") }}</AText>
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.icon {
	width: 52px;
	height: 52px;
	border-radius: 10px;
	box-shadow: 0 0 10px 0 var(--custom-6);
}
</style>
